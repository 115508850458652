import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Content, Page, Typography} from '@hconnect/uikit'
import {InputMessage} from '@hconnect/uikit/src/lib2'
import {Add, Close, Launch, SaveOutlined} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import {isEmpty} from 'lodash'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router'

import {useUser} from '../../common/hooks/useUser'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'
import {RoleAssignment, RoleType} from '../../modules/ManageUsers.selectors'
import {useUpdateUser} from '../ManageUser/hooks/useUpdateUser'
import {WidgetCustomerInput} from '../WidgetUserCreation/components/WidgetCustomerInput'
import {WidgetPermissionInput} from '../WidgetUserCreation/components/WidgetPermissionInput'
import {customerRoleSetup} from '../WidgetUserCreation/utils/CustomerRoleSetup'
import {WidgetUserCreationFormData} from '../WidgetUserCreation/WidgetUserCreation.form'
import {useWidgetUserCreationStyles} from '../WidgetUserCreation/WidgetUserCreation.styles'
import {
  CustomerToggleBasedPermissionKeys,
  CustomerToggleBasedPermissions,
  DataScopeOption
} from '../WidgetUserCreation/WidgetUserCreation.types'

import {InviteUser} from './components/InviteUser'
import {ResetPassword} from './components/ResetPassword'
import {UserDetailsField} from './components/UserDetailsField'
import {UserForm} from './components/UserForm'
import {Header} from './header/Header'
import {useCreateUserRoles} from './hooks/useCreateUserRoles'
import {useDeleteUserRoles} from './hooks/useDeleteUserRoles'
import {useUpdateUserRoles} from './hooks/useUpdateUserRoles'
import {useStyles} from './QuickPreview.styles'
import {QuickPreviewSkeleton} from './QuickPreviewSkeleton'

// eslint-disable-next-line complexity
export const QuickPreview = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const {classes: widgetClasses} = useWidgetUserCreationStyles()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const [cscData, setCscData] = useState<RoleAssignment[]>()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const {data: loggedInUserRoles} = useUserRoles(loggedInUserProfile?.user_id)
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<DataScopeOption>()
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>()
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<string | undefined>()
  const [initPermissions, setInitPermissions] = useState<CustomerToggleBasedPermissions>({
    [CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries]: true,
    [CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders]: false,
    [CustomerToggleBasedPermissionKeys.canSeeInvoices]: false
  })
  const {userId} = useParams<{userId: string}>()

  const {
    mutate: addPermission,
    isError: isAddPermissionError,
    isSuccess: isAddPermissionSuccess
  } = useCreateUserRoles()

  const {
    mutate: updatePermission,
    isError: isUpdatePermissionError,
    isSuccess: isUpdatePermissionSuccess
  } = useUpdateUserRoles()

  const {
    mutate: removePermission,
    isError: isRemovePermissionError,
    isSuccess: isRemovePermissionSuccess
  } = useDeleteUserRoles()

  const {
    data: user,
    refetch: refetchUser,
    isLoading: isUserLoading,
    isError: isUserError
  } = useUser(userId)

  const {data: roles, isLoading: isRolesLoading, isError: isRolesError} = useUserRoles(user?.id)

  const countries = roles
    ?.map((role) => role.dataScope?.['countryId'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)
    .filter((value, index, array) => array.indexOf(value) === index)

  const businessLines = roles
    ?.map((role) => role.dataScope?.['businessLine'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)
    .filter((value, index, array) => array.indexOf(value) === index)

  const orgUnits = roles
    ?.map((role) => role.dataScope?.['orgUnitId'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)
    .filter((value, index, array) => array.indexOf(value) === index)

  const customerAndSiteIds = roles
    ?.flatMap((role) => {
      if (role.dataScope?.['customerIds']?.length > 1) {
        return (role.dataScope?.['customerIds'] as string[]).map((customerId) => ({
          customerId,
          siteIds: []
        }))
      }
      return [
        {
          customerId: role.dataScope?.['customerIds']?.[0],
          siteIds: role.dataScope?.['siteIds'] as string[]
        }
      ]
    })
    .reduce((a, b) => a?.concat(b), [{customerId: '', siteIds: ['']}])
    .filter((value) => !!value.customerId)
    .filter(
      (value, index, array) => array.map((el) => el.customerId).indexOf(value.customerId) === index
    )

  const isRoleTooComplex =
    countries &&
    businessLines &&
    orgUnits &&
    (countries.length > 1 || businessLines.length > 1 || orgUnits.length > 1)

  const defaultPermissionsFormValues = {
    name: user?.name ?? '',
    email: user?.eMail ?? '',
    mobileNumber: user?.mobileNumber ?? '',
    customerIds: customerAndSiteIds,
    orgUnit:
      orgUnits && orgUnits?.length === 1
        ? orgUnits?.[0]
        : (cscData?.[0]?.dataScope?.['orgUnitId'] as string),
    products: [],
    businessLine:
      businessLines && businessLines.length === 1
        ? businessLines[0]
        : (cscData?.[0].dataScope?.['businessLine'] as string),
    permissions: initPermissions
  }

  const formMethods = useForm<WidgetUserCreationFormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultPermissionsFormValues
  })

  const {handleUpdateUser, isUpdateUserSuccess} = useUpdateUser({
    formMethods,
    refetchUser,
    userData: user,
    emailFieldName: 'email',
    mobileNumberFieldName: 'mobileNumber'
  })

  const [isEditing, setEditing] = useState(false)
  const [displayPermissionConfirmation] = useState(false)

  useEffect(() => {
    if (isAddPermissionSuccess || isUpdatePermissionSuccess || isRemovePermissionSuccess) {
      enqueueSnackbar(t('quickPreview.addPermissions.form.successUpdateMsg'), {
        anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
        variant: 'success',
        preventDuplicate: true,
        persist: true,
        action: (key) => (
          <Button
            style={{color: '#FFFFFF', borderColor: 'transparent'}}
            onClick={() => closeSnackbar(key)}
          >
            Close
          </Button>
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddPermissionSuccess, isUpdatePermissionSuccess, isRemovePermissionSuccess])

  useEffect(() => {
    if (selectedCustomerId && !selectedCustomerNumber) {
      // formMethods.resetField('customerId', {defaultValue: ''})
      setSelectedCustomerId(undefined)
    }
  }, [selectedCustomerNumber])

  useEffect(() => {
    if (selectedOrgUnit && selectedCustomerNumber) {
      // formMethods.resetField('customerId', {defaultValue: ''})
      setSelectedCustomerNumber(undefined)
    }
  }, [selectedOrgUnit])

  useEffect(() => {
    if (loggedInUserRoles && loggedInUserRoles?.length > 0) {
      const cscRole = loggedInUserRoles?.filter(
        (role) => role.roleType === 'CUSTOMER_SERVICE_CENTER' || role.roleType === 'SALES_AGENT'
      )
      setCscData(cscRole)
    }
  }, [loggedInUserRoles])

  useEffect(() => {
    if (roles) {
      const loadedRolePermissions = {
        [CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries]: !!roles.find(
          (role) =>
            role.roleType === 'FINISHER' ||
            role.roleType === 'ORDER_PLACER' ||
            role.roleType === 'ACCOUNTANT'
        ),
        [CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders]: !!roles.find(
          (role) => role.roleType === 'ORDER_PLACER'
        ),
        [CustomerToggleBasedPermissionKeys.canSeeInvoices]: !!roles.find(
          (role) => role.roleType === 'ACCOUNTANT'
        )
      }
      setInitPermissions(loadedRolePermissions)
      formMethods.reset({...defaultPermissionsFormValues, permissions: loadedRolePermissions})
    }
  }, [roles])

  useEffect(() => {
    handleFormMode(!isEmpty(formMethods.formState.dirtyFields))
  }, [formMethods.formState])

  useEffect(() => {
    if (
      !isAddPermissionError &&
      !isUpdatePermissionError &&
      !isRemovePermissionError &&
      (formMethods.formState.dirtyFields.name ||
      formMethods.formState.dirtyFields.email ||
      formMethods.formState.dirtyFields.mobileNumber
        ? isUpdateUserSuccess
        : true)
    ) {
      formMethods.reset({}, {keepValues: true, keepDirty: false, keepDirtyValues: false})
    }
  }, [isAddPermissionSuccess, isUpdatePermissionSuccess, isUpdateUserSuccess])

  const handleFormMode = (edit: boolean) => {
    setEditing(edit)
  }

  const handleCancel = () => {
    setSelectedOrgUnit(undefined)
    formMethods.reset(defaultPermissionsFormValues)
  }

  const handleRedirect = () => {
    trackEvent('cscQuickPreviewGoToFullMHC', {
      userId: loggedInUserProfile?.user_id,
      previewedUserId: userId,
      product: 'adminconsole'
    })
    window.open(`${window.location.origin}/manage/${userId}`, '_blank')
  }

  const {fields, append, remove} = useFieldArray({
    name: 'customerIds' as const,
    control: formMethods.control
  })

  if (isUserLoading || isRolesLoading) {
    return <QuickPreviewSkeleton />
  }

  if (isUserError || isRolesError) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        {t('error.notFound')}
      </Box>
    )
  }

  const handleSubmitForm = async (data: WidgetUserCreationFormData) => {
    const shouldUpdateUserData =
      !!formMethods.formState.dirtyFields['name'] ||
      !!formMethods.formState.dirtyFields['email'] ||
      !!formMethods.formState.dirtyFields['mobileNumber']
    const shouldUpdateRoleData =
      !!formMethods.formState.dirtyFields['customerIds'] ||
      !!formMethods.formState.dirtyFields['permissions']

    const formattedCustomerIds = data.customerIds.filter(
      (customerData) => !!customerData.customerId
    )

    const assignUserRolesData: RoleAssignment[] = customerRoleSetup(data.permissions).flatMap(
      (roleType) => {
        const areSiteIdsPresent =
          formattedCustomerIds.filter((customerId) => customerId.siteIds?.length > 0).length > 0

        return areSiteIdsPresent
          ? formattedCustomerIds.map((customerId) => ({
              userId: user?.user_id || '',
              id: -1,
              roleType: roleType as RoleType,
              dataScope: {
                countryId: countries?.[0] || cscData?.[0].dataScope['countryId'] || '',
                businessLine:
                  businessLines && businessLines.length === 1
                    ? businessLines[0]
                    : cscData?.[0].dataScope['businessLine'] ||
                      formMethods.formState.defaultValues?.businessLine ||
                      '',
                orgUnitId: selectedOrgUnit?.value || formMethods.watch('orgUnit') || '',
                customerIds: [customerId.customerId],
                ...(customerId.siteIds?.length > 0 && {siteIds: customerId.siteIds})
              }
            }))
          : {
              userId: user?.user_id || '',
              id: -1,
              roleType: roleType as RoleType,
              dataScope: {
                countryId: countries?.[0] || cscData?.[0].dataScope['countryId'] || '',
                businessLine:
                  businessLines && businessLines.length === 1
                    ? businessLines[0]
                    : cscData?.[0].dataScope['businessLine'] ||
                      formMethods.formState.defaultValues?.businessLine ||
                      '',
                orgUnitId: selectedOrgUnit?.value || formMethods.watch('orgUnit') || '',
                customerIds: formattedCustomerIds.map(
                  (customerData) => customerData.customerId
                ) || ['']
              }
            }
      }
    )

    const rolesToUpdate =
      assignUserRolesData
        ?.filter(
          (assignRoleData) =>
            roles?.map((role) => role.roleType).includes(assignRoleData.roleType) &&
            roles?.map(
              (role) => role.dataScope['countryId'] === assignRoleData.dataScope['countryId']
            ) &&
            roles?.map(
              (role) => role.dataScope['businessLine'] === assignRoleData.dataScope['businessLine']
            ) &&
            roles?.map(
              (role) => role.dataScope['orgUnitId'] === assignRoleData.dataScope['orgUnitId']
            ) &&
            roles
              ?.map((role) => role.dataScope['customerIds'])
              .some(
                (arr) =>
                  JSON.stringify(arr) === JSON.stringify(assignRoleData.dataScope['customerIds'])
              )
        )
        .map((assignRoleData) => ({
          ...assignRoleData,
          id:
            roles?.find(
              (role) =>
                role.roleType === assignRoleData.roleType &&
                role.dataScope['countryId'] === assignRoleData.dataScope['countryId'] &&
                role.dataScope['businessLine'] === assignRoleData.dataScope['businessLine'] &&
                role.dataScope['orgUnitId'] === assignRoleData.dataScope['orgUnitId'] &&
                JSON.stringify(role.dataScope['customerIds']) ===
                  JSON.stringify(assignRoleData.dataScope['customerIds'])
            )?.id || -1
        })) || []

    const rolesToAdd = assignUserRolesData.filter(
      (assignRoleData) =>
        !roles?.find(
          (role) =>
            role.roleType === assignRoleData.roleType &&
            role.dataScope['countryId'] === assignRoleData.dataScope['countryId'] &&
            role.dataScope['businessLine'] === assignRoleData.dataScope['businessLine'] &&
            role.dataScope['orgUnitId'] === assignRoleData.dataScope['orgUnitId'] &&
            JSON.stringify(role.dataScope['customerIds']) ===
              JSON.stringify(assignRoleData.dataScope['customerIds'])
        )?.id
    )

    const rolesToDelete =
      roles?.filter(
        (role) => !rolesToUpdate.map((roleToUpdate) => roleToUpdate.id).includes(role.id)
      ) || []

    trackEvent('cscQuickPreviewSubmitData', {
      userId: loggedInUserProfile?.user_id,
      previewedUserId: userId,
      product: 'adminconsole',
      formData: JSON.stringify(data)
    })

    if (shouldUpdateUserData) {
      await handleUpdateUser({
        eMail: data.email,
        name: data.name,
        mobileNumber: data.mobileNumber
      })
    }

    if (shouldUpdateRoleData) {
      if (rolesToUpdate.length > 0) {
        updatePermission(rolesToUpdate)
      }

      if (rolesToAdd.length > 0) {
        addPermission(rolesToAdd)
      }

      if (rolesToDelete.length > 0) {
        removePermission(rolesToDelete)
      }
    }
  }

  const discardChanges = () => {
    trackEvent('cscQuickPreviewDiscardChanges', {
      userId: loggedInUserProfile?.user_id,
      previewedUserId: userId,
      product: 'adminconsole'
    })
    handleCancel()
    setEditing(!isEditing)
  }

  return (
    <Content>
      <Page variant="sheet" className={classes.page}>
        <form onSubmit={formMethods.handleSubmit(handleSubmitForm)}>
          <Header />
          {user && (
            <UserForm
              user={user}
              formMethods={formMethods}
              handleUpdateUser={handleUpdateUser}
              isUserLoading={isUserLoading}
            />
          )}
          {!isRoleTooComplex && (
            <Box style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
              {fields.map((field, index) => (
                <Box key={field.id} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                  <WidgetCustomerInput
                    key={field.id}
                    index={index}
                    formMethods={formMethods}
                    selectedOrgUnit={selectedOrgUnit}
                    cscData={cscData}
                    remove={remove}
                  />
                </Box>
              ))}
              <Button
                startIcon={<Add style={{color: '#00374d', fontSize: '16px'}} />}
                className={widgetClasses.verifyButton}
                style={{alignSelf: 'start'}}
                onClick={() => append({customerId: '', siteIds: ['']})}
              >
                Add Customer ID
              </Button>
            </Box>
          )}
          <UserDetailsField
            roles={roles}
            isRolesLoading={isRolesLoading}
            formMethods={formMethods}
            cscData={cscData}
          />
          <InviteUser user={user} refetchUser={refetchUser} />
          <Typography
            style={{fontSize: '18px', fontWeight: 600, marginBottom: '14px', marginTop: '48px'}}
          >
            {t('quickPreview.addPermissions.title')}
          </Typography>
          {isRoleTooComplex && (
            <InputMessage
              variant="info"
              message="User has too many roles to edit. Please go to full user profile for role edit actions. "
            />
          )}
          {(displayPermissionConfirmation || !isRoleTooComplex) && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                marginBottom: '24px'
              }}
            >
              {Object.keys(initPermissions).map((key) => (
                <Controller
                  key={key}
                  name={`permissions.${key as keyof typeof CustomerToggleBasedPermissionKeys}`}
                  control={formMethods.control}
                  render={({field}) => (
                    <WidgetPermissionInput
                      permissionKey={key}
                      checked={!!formMethods.watch('permissions')[key]}
                      onChange={(checked) => field.onChange(checked)}
                      disabled={
                        !formMethods.formState.dirtyFields.permissions?.[key] &&
                        !!formMethods.watch('permissions')[key]
                      }
                    />
                  )}
                />
              ))}
            </Box>
          )}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '24px',
              marginBottom: '24px',
              visibility: isEditing ? 'visible' : 'hidden',
              gap: '12px'
            }}
          >
            <Button
              type="button"
              variant="text"
              color="primary"
              startIcon={<Close style={{fontSize: '16px'}} />}
              onClick={discardChanges}
              disabled={formMethods.formState.isSubmitting}
              style={{color: '#016AD4', fontWeight: 500}}
            >
              {t('quickPreview.discardChanges')}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveOutlined style={{fontSize: '16px'}} />}
              disabled={formMethods.formState.isSubmitting}
              style={{backgroundColor: '#016AD4', fontWeight: 500, borderRadius: '6px'}}
            >
              {t('quickPreview.saveChanges')}
            </Button>
          </Box>
        </form>
        <ResetPassword user={user} />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleRedirect}
          data-test-id={`full-user-overview-btn-${userId}`}
          startIcon={<Launch style={{color: '#29333D', fontSize: '16px'}} />}
          style={{
            width: '100%',
            marginTop: '24px',
            color: '#29333D',
            fontWeight: 500,
            border: '1px solid #E8ECF0',
            borderRadius: '4px'
          }}
        >
          {t('quickPreview.fullUserOverview')}
        </Button>
      </Page>
    </Content>
  )
}

import {getOrgUnits} from '@hconnect/apiclient'
import {useQueries, useQuery} from '@tanstack/react-query'

import {api} from '../App.store'

export const useOrgUnit = (countryId: string, businessLine: string, refetchOnMount?: boolean) =>
  useQuery(
    ['orgUnitId', countryId, businessLine],
    async () => {
      const response = await getOrgUnits(api)(countryId, {businessLine})
      return response.data.map((orgUnit) => ({
        key: orgUnit.orgUnitId,
        label: orgUnit.orgUnitName
      }))
    },
    {refetchOnMount, enabled: !!countryId && !!businessLine}
  )

export const useOrgUnits = (countryId: string, businessLines: string[]) => {
  const orgUnitsResult = useQueries({
    queries: (businessLines || []).map((businessLine) => ({
      queryKey: ['orgUnitId', countryId, businessLine],
      queryFn: async () => {
        const response = await getOrgUnits(api)(countryId, {businessLine})
        return response.data.map((orgUnit) => ({
          key: orgUnit.orgUnitId,
          label: orgUnit.orgUnitName
        }))
      },
      enabled: businessLine.length > 0
    }))
  })
  return {
    orgUnits: orgUnitsResult.map((result) => result.data || []).flat(),
    isLoading: orgUnitsResult.some((data) => data.isFetching)
  }
}

export const useCountriesOrgUnits = (countryIds: string[], enabled?: boolean) => {
  const orgUnitsResult = useQueries({
    queries: (countryIds || []).map((countryId) => ({
      queryKey: ['orgUnitId', countryId],
      queryFn: async () => {
        const response = await getOrgUnits(api)(countryId)
        return response.data.map((orgUnit) => ({
          key: orgUnit.orgUnitId,
          label: orgUnit.orgUnitName
        }))
      },
      enabled: enabled === undefined ? countryIds.length > 0 : countryIds.length > 0 && enabled
    }))
  })
  return {
    orgUnits: orgUnitsResult.map((result) => result.data || []).flat(),
    isLoading: orgUnitsResult.some((data) => data.isFetching)
  }
}

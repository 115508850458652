import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import {useEffect, useState} from 'react'

import {UsersTableFilter} from '../UsersList.types'

export const useUsersListFilters = (
  filter: UsersTableFilter,
  setFilter: (filter: UsersTableFilter) => void,
  isExternalUser: boolean,
  dataLoaded: boolean,
  setAreFiltersSetup: (setup: boolean) => void,
  areFiltersLoading?: boolean
) => {
  const [filterInputValues, setFilterInputValues] = useState<TableFilterType[]>([])
  const [removedFilter, setRemovedFilter] = useState<TableFilterType>()
  const [shouldResetPage, setShouldResetPage] = useState(false)

  useEffect(() => {
    if (dataLoaded) {
      const formattedInputValues = filterInputValues.map((value) => handleFilterValues(value))
      const mergeParams: UsersTableFilter = Object.assign(filter, ...formattedInputValues)
      const filteredParams = Object.keys(mergeParams)
        .filter((param) => {
          return removedFilter?.filterType === TableFilterTypeProps.DATEPICKER_RANGE
            ? removedFilter.filterDatePickerRangeOptions?.validTo?.filterField !== param &&
                removedFilter.filterDatePickerRangeOptions?.validFrom?.filterField !== param
            : removedFilter?.filterField !== param
        })
        .reduce((acc, key) => {
          const _acc = acc
          if (mergeParams[key] !== undefined && mergeParams[key] !== '')
            _acc[key] = mergeParams[key]
          return _acc
        }, {})

      const formattedParams = isExternalUser
        ? {...filteredParams, isTester: filteredParams?.['isTester'] || 'false'}
        : {...filteredParams}

      if (shouldResetPage) {
        setFilter({...formattedParams, page: 1} as UsersTableFilter)
        setShouldResetPage(false)
      } else {
        setFilter({...formattedParams} as UsersTableFilter)
      }

      setAreFiltersSetup(true)
      setRemovedFilter(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInputValues, isExternalUser, dataLoaded])

  const handleFilterValues = (value: TableFilterType) => {
    if (
      value.filterDatePickerRangeOptions?.validTo &&
      value.filterDatePickerRangeOptions?.validFrom &&
      value.filterDatePickerRangeOptions?.validTo.filterField &&
      value.filterDatePickerRangeOptions?.validFrom.filterField
    ) {
      return {
        [value.filterDatePickerRangeOptions.validTo.filterField]:
          value.filterDatePickerRangeOptions.validTo.value,
        [value.filterDatePickerRangeOptions.validFrom.filterField]:
          value.filterDatePickerRangeOptions.validFrom.value
      }
    } else {
      if (value.filterType === TableFilterTypeProps.DATEPICKER_RANGE && value.filterField) {
        return {[value.filterField]: undefined}
      }
    }

    if (value.filterField) {
      return {[value.filterField]: value.value}
    }
  }

  const handleFilterInputValues = (props: TableFilterItemProps) => {
    setShouldResetPage(true)
    const filterInputIndex = filterInputValues.findIndex(
      (item) => item.filterField === props.filterField
    )

    const newFilterInputValues = [...filterInputValues]
    if (filterInputIndex === -1) {
      setFilterInputValues([
        ...filterInputValues,
        {filterField: props.filterField || '', value: props.value || ''}
      ])
    } else {
      newFilterInputValues[filterInputIndex].value = props.value || ''
      setFilterInputValues(newFilterInputValues)
    }
  }

  const handleDatePickerFilterInputValues = (props: TableFilterItemProps) => {
    setShouldResetPage(true)
    const datePickerValidFromInputIndex = filterInputValues.findIndex(
      (item) =>
        item.filterDatePickerRangeOptions?.validFrom?.filterField ===
        props.filterDatePickerRangeOptions?.validFrom.filterField
    )

    const datePickerValidToInputIndex = filterInputValues.findIndex(
      (item) =>
        item.filterDatePickerRangeOptions?.validTo?.filterField ===
        props.filterDatePickerRangeOptions?.validTo?.filterField
    )

    const newFilterInputValues = [...filterInputValues]

    if (datePickerValidFromInputIndex === -1 && datePickerValidToInputIndex === -1) {
      setFilterInputValues([
        ...filterInputValues,
        {
          filterField: props.filterDatePickerRangeOptions?.validFrom.filterField || '',
          value: props.filterDatePickerRangeOptions?.validFrom.value
        },
        {
          filterField: props.filterDatePickerRangeOptions?.validTo.filterField || '',
          value: props.filterDatePickerRangeOptions?.validTo.value
        }
      ])
    } else {
      newFilterInputValues[
        datePickerValidFromInputIndex
      ].filterDatePickerRangeOptions!.validFrom!.value =
        props.filterDatePickerRangeOptions?.validFrom.value
      newFilterInputValues[
        datePickerValidToInputIndex
      ].filterDatePickerRangeOptions!.validTo!.value =
        props.filterDatePickerRangeOptions?.validTo.value

      setFilterInputValues(newFilterInputValues)
    }
  }

  return {
    filter,
    setFilter,
    handleFilterInputValues,
    handleDatePickerFilterInputValues,
    filterInputValues,
    setFilterInputValues,
    setRemovedFilter
  }
}

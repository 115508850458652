import {Customer, FetchUsersParameters, Payer, User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import type {QueryFunction} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'

import {api} from '../../../App.store'
import {useLoggedInUser} from '../../../common/hooks/useLoggedInUser'
import {
  noResultsFromCustomerLookupLabel,
  noResultsFromPayerLookupLabel,
  tooManySiteIdsLabel
} from '../UsersList'
import {UserCategory} from '../UsersList.enums'
import {UserIssueKeys, UsersTableFilter} from '../UsersList.types'

type QueryKey = [
  string,
  {
    rowsPerPage: number
    page: number
    sortColumn: string
    sortDirection: 'desc' | 'asc'
    name?: string
    marketId?: string
    roleType?: string
    userCategory: UserCategory
    country?: string
    userIssue: UserIssueKeys
    createdBy?: boolean
    hideTestUser?: boolean
    isInternal?: boolean
    isTester?: string
    lastActivityProduct?: string
    customerId?: string
    payerId?: string
    contractId?: string
    plantId?: string
    eMail?: string
    businessLine?: string
    companyName?: string
    mobileNumber?: string
    createdOnFrom?: string
    createdOnTo?: string
    branding?: string
    loggedInUserId?: string
    blocklistNotifications?: string
    creationChannel?: string
    lastActivityDateFrom?: string
    lastActivityDateTo?: string
    language?: string
    loadCrossBorderUsers?: boolean
    orgUnitId?: string
    mhcSiteId?: string
  }
]

// TODO: cleanup, refactor. This has just been extracted from UsersList.
const mapUserIssueKeyToQueryParams = (userIssue: UserIssueKeys) => {
  if (userIssue === UserIssueKeys.ALL) {
    return
  }
  if (userIssue === UserIssueKeys.NO_PSW) {
    return {
      [UserIssueKeys.NEVER_LOGGED_IN]: true,
      [UserIssueKeys.IS_INVITE_SENT]: false
    }
  }
  if (userIssue === UserIssueKeys.IS_INVITE_SENT || userIssue === UserIssueKeys.HAS_ROLES) {
    return {[userIssue]: false}
  }
  if (
    userIssue === UserIssueKeys.NEVER_LOGGED_IN ||
    userIssue === UserIssueKeys.IS_LOCKED_OUT ||
    userIssue === UserIssueKeys.FAILED_LOGIN
  ) {
    return {[userIssue]: true}
  }
}

const getUsers = (
  url: string,
  filter: UsersTableFilter,
  loggedInUserId: string | undefined,
  customers?: string | string[],
  payers?: string | string[],
  sites?: string | string[]
) => {
  const params: FetchUsersParameters = {
    ...filter,
    skip: (filter.page - 1) * filter.rowsPerPage,
    limit: filter.rowsPerPage,
    isInternal: filter.isInternal,
    country: filter.country,
    roleType: filter.roleType === 'all' ? undefined : filter.roleType,
    isTester: filter.isTester,
    marketId: filter.marketId,
    // CreatedBy should be refactored to something more speaking
    createdBy: filter.createdBy ? loggedInUserId : undefined,
    lastActivityProduct: filter.lastActivityProduct,
    customerId: Array.isArray(customers) ? customers.join(',') : customers,
    payerId: Array.isArray(payers) ? payers.join(',') : payers,
    mhcSiteId: Array.isArray(sites) ? sites.join(',') : sites,
    contractId: filter.contractId,
    ...mapUserIssueKeyToQueryParams(filter.userIssue)
  }

  return api.get<User[]>(url, {
    params
  })
}

const getCustomers = async (customerNumber?: string) => {
  try {
    const params = {
      customerNumber: customerNumber
    }
    const customers = await api.get<Customer[]>('/customers', {params})
    return customers.data.map((customer) => customer.customerId)
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      throw new Error(noResultsFromCustomerLookupLabel)
    }
    throw error
  }
}

const getSites = async (siteNumber?: string) => {
  const params = {
    searchTerm: siteNumber,
    skip: 0,
    limit: 11
  }
  const sites = await api.get('/sites', {params})
  const siteIds = sites.data.map((site) => site.siteId)
  if (siteIds && siteIds.length > 10) {
    throw new Error(tooManySiteIdsLabel)
  }
  return sites.data.map((site) => site.siteId)
}

const getPayers = async (payerNumber?: string) => {
  try {
    const params = {
      payerNumber: payerNumber
    }
    const payers = await api.get<Payer[]>('/payers', {params})
    return payers.data.map((payer) => payer.payerId)
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      throw new Error(noResultsFromPayerLookupLabel)
    }
    throw error
  }
}

const usersFetcher: QueryFunction<AxiosResponse<User[]> | null> = async ({queryKey}) => {
  const [base, {loggedInUserId, ...filter}] = queryKey as QueryKey

  try {
    const customers = filter.customerId && (await getCustomers(filter.customerId))
    const payers = filter.payerId && (await getPayers(filter.payerId))
    const sites = filter.mhcSiteId && (await getSites(filter.mhcSiteId))
    const users = await getUsers(
      `/${base}`,
      filter,
      loggedInUserId,
      customers && customers.length ? customers : filter.customerId,
      payers && payers.length ? payers : filter.payerId,
      sites && sites.length ? sites : filter.mhcSiteId
    )
    return users
  } catch (e) {
    const error = e as AxiosError
    console.error(error)
    trackEvent('adminConsoleError', {
      product: 'adminConsole',
      date: new Date().toISOString(),
      errorCode: error.response?.status,
      component: 'useUsers.ts usersFetcher',
      endpoint: error.response?.config?.url
    })
    return null
  }
}

export const useUsers = (filter: UsersTableFilter, enabled: boolean) => {
  const loggedInUserId = useLoggedInUser()?.id
  return useQuery<AxiosResponse<User[]> | null, AxiosError, AxiosResponse<User[]>, QueryKey>(
    [
      'users',
      {
        rowsPerPage: filter.rowsPerPage,
        page: filter.page,
        sortColumn: filter.sortColumn,
        sortDirection: filter.sortDirection,
        name: filter.name,
        marketId: filter.marketId,
        isTester: filter.isTester,
        roleType: filter.roleType,
        userCategory: filter.userCategory,
        country: filter.country,
        userIssue: filter.userIssue,
        createdBy: filter.createdBy,
        hideTestUser: filter.hideTestUser,
        isInternal: filter.isInternal,
        lastActivityProduct: filter.lastActivityProduct,
        customerId: filter.customerId,
        payerId: filter.payerId,
        contractId: filter.contractId,
        plantId: filter.plantId,
        mhcSiteId: filter.mhcSiteId,
        orgUnitId: filter.orgUnitId,
        eMail: filter.eMail,
        businessLine: filter.businessLine,
        companyName: filter.companyName,
        mobileNumber: filter.mobileNumber,
        createdOnFrom: filter.createdOnFrom,
        createdOnTo: filter.createdOnTo,
        branding: filter.branding,
        blocklistNotifications: filter.blocklistNotifications,
        loggedInUserId,
        creationChannel: filter.creationChannel,
        lastActivityDateFrom: filter.lastActivityDateFrom,
        lastActivityDateTo: filter.lastActivityDateTo,
        language: filter.language,
        loadCrossBorderUsers: filter.loadCrossBorderUsers
      }
    ],
    usersFetcher,
    {
      retry: false,
      enabled
    }
  )
}
